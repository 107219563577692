<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_inline_block">
          <div class="mainList_operation_search">
            <!-- 部门 -->
            <div class="search_center">
              <span>费用所属部门：</span>
              <DeptCascader
                :placeholder="'请选择费用所属部门'"
                :deptList="deptList"
                v-model="parameter.deptId"
              />
            </div>

            <!-- 姓名 -->
            <div class="search_center">
              <span>姓名：</span>
              <el-input
                v-model="parameter.userName"
                @change="dataUpdate"
                class="ipt_width"
                placeholder="请输入姓名"
              >
              </el-input>
            </div>
            <!-- 单据编号 -->
            <div class="search_center">
              <span>单据编号：</span>
              <el-input
                v-model="parameter.expenseNumber"
                @change="dataUpdate"
                class="ipt_width expense__width"
                placeholder="请输入单据编号"
              >
              </el-input>
            </div>

            <!-- 所属单位 -->
            <div class="search_center">
              <span>所属单位：</span>
              <el-select
                v-model="parameter.companyType"
                @change="dataUpdate"
                filterable
                placeholder="请选择所属单位"
                class="ipt_width"
              >
                <el-option
                  v-for="item in companyTypeList"
                  :key="item.id"
                  :label="item.dictName"
                  :value="item.dictVal"
                >
                </el-option>
              </el-select>
            </div>

            <div class="search_center">
              <span>申请时间：</span>
              <el-date-picker
                class="date_width"
                v-model="createdDate"
                :pickerOptions="pickerOptions"
                type="daterange"
                range-separator="至"
                start-placeholder="申请日期（始）"
                end-placeholder="申请日期（末）"
              >
              </el-date-picker>
            </div>
            <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)"
              >搜索</el-button
            >
            <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
          </div>
        </div>
        <div class="fr">
          <el-button type="primary" @click="drawer = true">报销流程</el-button>
        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-tabs v-model="parameter.approvalType" class="fullScreenMainHeader">
            <el-tab-pane name="NOT_PAYED">
              <span slot="label">待报销 </span>
            </el-tab-pane>
            <el-tab-pane name="ALREADY_PAYED">
              <span slot="label">已报销 </span>
            </el-tab-pane>
          </el-tabs>
          <el-table
            v-loading="loading"
            ref="multipleTable"
            tooltip-effect="dark"
            height="string"
            border
            :data="tableData"
          >
            <el-table-column type="index" label="序号" align="center" width="50"></el-table-column>
            <el-table-column
              prop="expenseNumber"
              label="单据编号"
              align="center"
              width="160"
            ></el-table-column>
            <el-table-column
              prop="createName"
              sortable
              label="姓名"
              align="center"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="deptName"
              sortable
              min-width="130"
              label="费用所属部门"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="companyTypeName"
              sortable
              width="130"
              label="费用所属单位"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="priority"
              :show-overflow-tooltip="false"
              min-width="120"
              label="是否优先发放"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.priority == 1 ? '是' : '否' }}
              </template>
            </el-table-column>
            <el-table-column
              prop="csumCostost"
              width="110"
              sortable
              label="报销金额"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.sumCost | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column
              prop="projectBudget"
              width="110"
              sortable
              label="申请时间"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.taskDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              sortable
              width="180"
              prop="expenseMode"
              label="报销方式"
            >
              <template slot-scope="scope">
                {{ scope.row.expenseMode | dict(expenseModeList) }}
              </template></el-table-column
            >
            <el-table-column
              align="center"
              sortable
              width="180"
              prop="applyExpenseType"
              label="付款方式"
            >
              <template slot-scope="scope">
                {{ scope.row.applyExpenseType | dict(applyExpenseType) }}
              </template></el-table-column
            >
            <el-table-column align="center" width="150" sortable prop="statusName" label="流程进度">
            </el-table-column>
            <el-table-column label="操作" width="70" fixed="right" align="center">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  v-if="parameter.approvalType == 'NOT_PAYED'"
                  class="text_Grant_Bgc"
                  @click="handleDetail(scope.row)"
                  >发放</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  v-else
                  class="text_Details_Bgc"
                  @click.stop="handleDetail(scope.row)"
                  >详情</el-button
                >
                <!-- <el-button
                  type="text"
                  size="small"
                  class="text_Grant_Bgc"
                  @click.stop="affirmCost(scope.row.id)"
                  v-if="parameter.approvalType == 'NOT_PAYED'"
                  >确认报销</el-button
                > -->
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="parameter.total"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>

          <!-- 财务确认报销弹出框 -->
          <!-- <EditDialog :isShow.sync="dialogFormVisible" @submit="submit">
            <div slot="title" class="title">确认报销</div>
            <div slot="content" class="content">
              <el-form :model="dg_form" label-width="100px">
                <el-form-item label="附件" prop="remark">
                  <div class="iconCost">
                    <Upload :type="type" :fields="fields" @upload="upload"></Upload>
                  </div>

                  <div
                    v-for="(n, index) in dg_form.affirmCostFileList"
                    :key="index"
                    style="margin-top:5px"
                  >
                    <a
                      class="fj-text"
                      target="_blank"
                      style="text-decoration:revert;color:#409eff;padding-right:8px;"
                      :href="`${filepath}${n.filePath}`"
                    >
                      {{ n.fileName }}
                    </a>

                    <i @click="del(index)" class="iconfont iconerror iconCost"></i>
                  </div>
                </el-form-item>

                <el-form-item label="备注" prop="remark">
                  <el-input v-model="dg_form.remark" type="textarea"></el-input>
                </el-form-item>
              </el-form>
            </div>
          </EditDialog> -->
        </div>
      </div>
    </div>
    <el-dialog width="75%" title="公共费用报销流程图" :visible.sync="drawer">
      <div style="padding: 5px">
        <img src="@/assets/公共费用报销.png" alt="公共费用报销流程图" width="100%" />
      </div>
    </el-dialog>
    <Edit v-if="editShow" :isShow.sync="editShow" :getTitle="sonTitle" :options="form"></Edit>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    Upload: () => import('@/components/upload/Upload.vue'),
    Edit: () => import('../edit/CostEdit.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    DeptCascader: () => import('@/components/dept/DeptCascader.vue'),
  },
  data() {
    return {
      loading: false, // 表格 加载
      parameter: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
        approvalType: 'NOT_PAYED', //1未审核，2已审核，3未通过
        expenseNumber: null, //申请编号
        userName: null, //申请人姓名
        deptId: null, //部门id
        startDate: null, //查询开始时间
        endDate: null, //查询结束时间
        companyType: null,
      },
      pageSize: 0,
      total: 0, // 表格 -- 数据 总数量
      tableData: [], // 表格数据
      editShow: false, // "编辑 -- 组件" 隐藏 或 显示
      companyTypeList: [], //公司所属单位列表
      createdDate: [], //查询内容申请时间
      drawer: false, //流程图显示隐藏
      deptList: [], // 部门 列表
      // dialogFormVisible: false,
      // dg_form: {
      //   projectCostFileList: [],
      //   remark: "",
      // },
      // filepath: process.env.VUE_APP_FILEPATH,
      // fields: {
      //   name: "fileName",
      //   path: "filePath",
      //   type: "type",
      // },
      // type: "GGFYBX",
      expenseModeList: [],
      pickerOptions: {
        onPick: v => {
          if (v.minDate && !v.maxDate) {
            this.$set(this.createdDate, 0, v.minDate.getTime())
            this.$set(this.createdDate, 1, '')
          }
        },
      },
      applyExpenseType: [], //付款方式字典
    }
  },
  provide() {
    return {
      re: this.closeSubgroup,
    }
  },
  async created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    await this.getCompanyList() /** 加载 "所属单位" 列表数据 **/
    await this.getDeptList() /** 加载 "部门" 列表数据 **/
    await this.getTableData()
    this.$api.dict.listSysDictData('EXPENSE_MODE', true).then(res => {
      this.expenseModeList = res.data
    })
    this.$api.dict
      .listSysDictData('APPLY_EXPENSE_TYPE', true)
      .then(res => {
        this.applyExpenseType = res.data
      })
      .catch(err => {
        console.log(err)
      })
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  watch: {
    'parameter.approvalType': {
      // immediate:true,//初始化立即执行
      // deep: true,//对象深度监测
      handler: function (newVal, oldVal) {
        this.parameter.pageNow = 1
        this.getTableData()
      },
    },
    tableData: {
      // immediate:true,//初始化立即执行
      deep: true,
      handler: function (newVal, oldVal) {
        let num = newVal.length
        if (num === 0 && this.parameter.pageNow > 1 && this.parameter.pageNow !== 1) {
          this.parameter.pageNow -= 1
          this.getTableData()
        }
      },
    },
    createdDate: {
      deep: true,
      handler: function (val) {
        if (val == null) {
          this.createdDate = []
        }
      },
    },
  },
  methods: {
    getDeptList() {
      this.$api.sysDept
        .getTreeDept()
        .then(res => {
          this.deptList = res.data
          this.$store.commit({
            type: 'business/SET_DEPT_LIST',
            selectDeptList: res.data,
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    getCompanyList() {
      this.$api.dict
        .listSysDictData('COMPANY_TYPE')
        .then(res => {
          this.companyTypeList = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },

    //全局分页保存
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }

      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getTableData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getTableData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getTableData()
    },

    /** 加载数据 **/
    getTableData() {
      if (this.createdDate && this.createdDate != []) {
        this.parameter.startDate = new Date(this.createdDate[0]).getTime()
        this.parameter.endDate = new Date(this.createdDate[1]).getTime()
      } else if (this.createdDate == null) {
        this.parameter.startDate = ''
        this.parameter.endDate = ''
      }
      this.loading = true
      this.$api.publicSpending
        .searchTask(this.parameter)
        .then(res => {
          this.loading = false
          let result = res.data ? res.data.records : []
          result.forEach(item => {
            // 根据companyType查公司所属单位
            const findCompanyType = this.companyTypeList.find(
              companyTypeItem => companyTypeItem.dictVal == item.companyType
            )
            if (findCompanyType) {
              item.companyTypeName = findCompanyType.dictName
            }
            item.sumCost = Number(item.sumCost ? item.sumCost : 0)
          })
          this.tableData = result
          this.parameter.total = res.data.total || 0
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    /**  点击 "重置" 按钮 **/
    handleReset() {
      this.parameter.startDate = null
      this.parameter.endDate = null
      this.parameter.expenseNumber = null
      this.parameter.userName = null
      this.parameter.deptId = null
      this.parameter.companyType = null
      this.parameter.pageNow = 1
      this.createdDate = []
      this.getTableData()
    },

    /**
     * 点击 "表格 -- 详情" 操作
     * @param item      当前数据
     */
    handleDetail(item) {
      this.sonTitle = '支付报销单详情'
      this.form = item
      this.form.detailType = 0
      this.form.list_s = 3
      this.form.passText = '发放'
      this.form.hideRejectButton = true
      this.form.showExportButton = true
      this.editShow = true
    },

    /**
     * 点击 "表格 -- 分页" 操作
     * @param page      当前页
     */
    handlerPage(page) {
      this.parameter.pageNow = page
      this.getTableData()
    },

    /** 关闭 "编辑" 组件 **/
    closeSubgroup() {
      this.form = {}
      this.getTableData()
      this.editShow = false
    },

    dataUpdate() {
      this.$forceUpdate()
    },

    // affirmCost(id) {
    //   this.dg_form = {
    //     affirmCostFileList: [],
    //     remark: "",
    //   };
    //   this.dg_form.id = id;
    //   this.dialogFormVisible = true;
    // },

    // submit() {
    //   this.loading = true;
    //   this.$api.publicSpending
    //     .affirmApply(this.dg_form)
    //     .then((res) => {
    //       this.loading = false;
    //       this.$message({
    //         type: "success",
    //         message: "成功!",
    //       });
    //       this.getTableData();
    //       this.dialogFormVisible = false;
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       this.loading = false;
    //     });
    // },

    // upload(r) {
    //   r[0].type = "GGFYBX";
    //   this.dg_form.affirmCostFileList.push(...r);
    // },

    // del(index) {
    //   this.dg_form.affirmCostFileList.forEach((element, i) => {
    //     if (i == index) {
    //       this.dg_form.affirmCostFileList.splice(index, 1);
    //     }
    //   });
    // },
  },

  filters: {
    applyAmount(n) {
      if (n) {
        return Number(n).toLocaleString()
      } else {
        return ''
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.oneline {
  display: flex;
  justify-content: space-between;
}
// .search_center {
//   display: flex;
//   align-items: center;
// }

// .row1 {
//   display: flex;
// }

.iconCost {
  padding-left: 10px;
  font-size: 28px;
  vertical-align: middle;
  cursor: pointer;
}
</style>
